import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Container from 'common/source/components/UI/Container';
import Image from 'reusecore/src/elements/Image';
import PostWrapper from './post.style';

const PostView = ({ post }) => {
  const { frontmatter } = post;
  const [day, month, year] = frontmatter.date.split('/');

  const postDate = format(new Date(year, month, day), "do 'de' MMMM yyyy", {
    locale: es
  });
  return (
    <Container>
      <PostWrapper>
        <div className='post-meta'>{postDate}</div>
        <div className='post-info'>
          <h1>{frontmatter.title}</h1>
        </div>
        <div className='post-cover'>
          <Image
            src={frontmatter.cover}
            alt={`solar-power ${frontmatter.title}`}
          />
        </div>
        <div
          className='post-body'
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </PostWrapper>
    </Container>
  );
};

export default PostView;
