import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import Navbar from 'common/source/containers/Navbar';

import PostView from 'common/source/containers/PostView';
// import BlogFooterList from 'common/source/containers/BlogList/BlogFooterList';

import Footer from 'common/source/containers/Footer';

import SEO from '../components/SEO';

class PostsTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    // const { slug, prevPost, nextPost } = pageContext;
    const { slug } = pageContext;
    const { post } = data;

    const { frontmatter } = post;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={post} postSEO prefix='blog' />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
              <DrawerProvider>
                <Navbar background='purple' />
              </DrawerProvider>
            </Sticky>

            <PostView post={post} />
            {/* <BlogFooterList prevPost={prevPost} nextPost={nextPost} /> */}
            <br />
            <br />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default PostsTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    post: markdownRemark(
      frontmatter: { slug: { eq: $slug }, type: { eq: "BLOG" } }
    ) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        author
        type
        cover
        date
        tags
      }
    }
  }
`;
